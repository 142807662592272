export const appBuildAssetsDir = "/_nuxt3/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"},{"name":"description","content":"Transform presentations, foster meaningful dialogues and maximize investor  engagements with VRIFY’s captivating 3D visualizations and 360-degree interactive tools."},{"property":"og:description","content":"Transform presentations, foster meaningful dialogues and maximize investor engagements with VRIFY’s captivating 3D visualizations and 360-degree interactive tools."},{"property":"og:type","content":"website"},{"name":"keywords","content":"revolutionizing communication, discover and research investment opportunities, empowering companies, empowering investors, 360-degree virtual reality, real time company alerts, real time live stock quotes, easy-to-use platform"},{"name":"robots","content":"index,follow"},{"name":"copyright","content":"© VRIFY Inc. All Rights Reserved."},{"name":"author","content":"VRIFY Inc."},{"name":"theme-color","content":"#ffffff"},{"name":"apple-itunes-app","content":"app-id=1235301790"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"manifest","href":"/manifest.json"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg"}],"style":[],"script":[{"src":"/js/leadfeeder.js","async":true}],"noscript":[],"title":"VRIFY - Make Every Investor Interaction Count","bodyAttrs":{"id":"vrify-body"}}

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false