import type { ClientDevice, GuestUser, User } from '@/entities/user'
import type { Company } from '@/entities/company'
import { API_LOGOUT_ENDPOINT, API_REFRESH_ENDPOINT } from '@/lib/fetch-constants'
import debounce from '@/lib/debounce'
import type { ComputedRef, DeepReadonly, Ref } from 'vue'
import type { ClientJS } from 'clientjs'
import Intercom, { shutdown as shutdownIntercom } from '@intercom/messenger-js-sdk'

export interface UseUserState {
  clientDevice: ClientDevice
  flags: { [key: string]: boolean }
  followingCompanies: Company[]
  guestUserInfo: GuestUser | undefined | null
  loading: boolean
  requestSetting: { id: number } | undefined
  token: string
  user: User | undefined
}

export interface UseUser {
  initialize(clientJs?: ClientJS): Promise<void>
  refreshToken(): Promise<void>
  create(body: { email_address: string; password: string }): Promise<void>
  createWithAlternateService(): Promise<void>
  logout(): Promise<void>
  setGuestUserInfo(guestUserInfo: GuestUser): void
  setFollowCompany(params: { action: 'follow' | 'unfollow'; id: string | number }): Promise<void>
  userRole: ComputedRef<string>
  userHasCompanies: ComputedRef<boolean>
  doesUserManageCompany(id: number): boolean
  hasRole(role: string): boolean
  selectedCompany: Readonly<Ref<Company | undefined>>
  setSelectedCompany(companyId: number): void
  inboxCount: Readonly<Ref<number>>
  state: DeepReadonly<Ref<UseUserState>>
}

export function useUser(): UseUser {
  const config = useRuntimeConfig()
  const route = useRoute()
  const { $analytics, $sentry, $clientJS } = useNuxtApp()

  const selectedCompany = useState<Company | undefined>('selectedCompany')
  const inboxCount = useState<number>('inboxCount', () => 0)
  const state = useState<UseUserState>('user', () => ({
    clientDevice: {} as ClientDevice,
    followingCompanies: [],
    loading: true,
    token: '',
    user: undefined,
    guestUserInfo: undefined,
    requestSetting: undefined,
    flags: {},
  }))

  const guestUserInfoCookie = useCookie<Object | null>('guest_user_info', { maxAge: 60 * 60 * 24 })

  async function initialize(clientJs: ClientJS = $clientJS): Promise<void> {
    state.value = {
      ...state.value,
      loading: true,
      guestUserInfo: guestUserInfoCookie.value,
    }

    try {
      await _getRefreshToken()
      const user = await $fetch<User>('v2/user', useFetchSharedOptions())

      await _setUserData({ user })
    } catch (e) {
      $sentry?.captureException(e)
      _destroy()
    } finally {
      _setDeviceInfo(clientJs)
      state.value.loading = false
    }
  }

  async function refreshToken() {
    try {
      await _getRefreshToken()
    } catch (e) {
      $sentry?.captureException(e)

      await $fetch(API_LOGOUT_ENDPOINT, {
        baseURL: config.public.API_ENDPOINT,
        method: 'POST',
        headers: useRequestHeaders(['cookie']) as HeadersInit,
        credentials: 'include',
      })

      _destroy()

      const redirectedFrom = route.name === 'login' ? route.query.redirectedFrom : route.path
      await navigateTo({ name: 'login', query: { ...route.query, redirectedFrom } })
    }
  }

  async function create(body: { email_address: string; password: string }) {
    const data = await $fetch<{ access: string; user: User }>('v2/auth', {
      method: 'POST',
      credentials: 'include',
      body,
      ...useFetchSharedOptions(),
    })

    const { access, user } = data || {}

    state.value = { ...state.value, token: access }

    try {
      await _setUserData({ user })

      guestUserInfoCookie.value = null
      state.value = { ...state.value, guestUserInfo: undefined }
    } catch (e) {
      _destroy()
    }
  }

  async function createWithAlternateService(): Promise<void> {
    try {
      await _getRefreshToken()
      const user = await $fetch<User>('v2/user', { ...useFetchSharedOptions() })

      await _setUserData({ user })
    } catch (e) {
      $sentry?.captureException(e)
      _destroy()
    }
  }

  async function logout(): Promise<void> {
    try {
      await $fetch(API_LOGOUT_ENDPOINT, {
        ...useFetchSharedOptions(),
        method: 'POST',
        headers: useRequestHeaders(['cookie']) as HeadersInit,
        credentials: 'include',
      })
    } catch (e) {
      throw e
    } finally {
      _destroy()
      window.location.href = '/'
    }
  }

  function setGuestUserInfo(guestUserInfo: GuestUser): void {
    guestUserInfoCookie.value = guestUserInfo
    state.value = { ...state.value, guestUserInfo }
  }

  async function setFollowCompany({ action, id }: { action: 'follow' | 'unfollow'; id: string | number }): Promise<void> {
    await $fetch(`v2/user/companies/${action}/${id}`, {
      method: 'POST',
      ...useFetchSharedOptions(),
    })

    await _fetchFollowingCompanies()
  }

  function setSelectedCompany(companyId: number) {
    selectedCompany.value = state.value.user?.companies.find(({ id }) => `${id}` === `${companyId}`) ?? state.value.user?.companies[0]
    if (selectedCompany.value) {
      localStorage.setItem('meetingsManagedCompany', `${selectedCompany.value.id}`)
      _getInboxCount()
    }
  }

  const userRole = computed(
    () => state.value.user?.roles?.find(({ key }) => ['super_admin', 'company_admin', 'account_lead'].includes(key))?.description ?? '',
  )

  const userHasCompanies = computed(() => {
    return !!state.value.user?.companies.length
  })

  function doesUserManageCompany(id: number): boolean {
    if (hasRole('super_admin')) return true

    return !!(state.value.user?.companies.find(c => c.id === id) ?? false)
  }

  function hasRole(role: string): boolean {
    if (role === 'internal_admin') {
      return hasRole('super_admin') || hasRole('account_lead')
    }

    return state.value.user?.roles?.some(({ key }) => key === role) ?? false
  }

  const _getInboxCount = debounce(async () => {
    if (!selectedCompany.value) return

    const { count } = await $fetch<{ count: number }>(`v2/companies/${selectedCompany.value?.id}/questions`, {
      params: { status: 'unread,read', archived: false, limit: 1 },
      ...useFetchSharedOptions(),
    })

    inboxCount.value = count
  }, 100)

  async function _getRefreshToken(): Promise<void> {
    const response = await $fetch<{ access: string }>(API_REFRESH_ENDPOINT, {
      method: 'POST',
      headers: useRequestHeaders(['cookie']) as HeadersInit,
      credentials: 'include',
      ...useFetchSharedOptions(),
    })

    state.value = { ...state.value, token: response.access }
  }

  async function _setUserData({ user }: { user: User }): Promise<void> {
    state.value = { ...state.value, user: user }

    const _localStorageManagedCompanyId = localStorage.getItem('meetingsManagedCompany')
    selectedCompany.value = user.companies.find(({ id }: { id: number }) => `${id}` === _localStorageManagedCompanyId) || user.companies[0]
    _getInboxCount()

    const [_, requestSettings, flags] = await Promise.all([
      _fetchFollowingCompanies(),
      $fetch<{ id: number }[]>('v2/meetings/request-settings', useFetchSharedOptions()),
      $fetch<{ name: string; is_active_for_user: boolean }[]>('v2/flags', useFetchSharedOptions()),
    ])

    state.value = {
      ...state.value,
      requestSetting: requestSettings[0],
      flags: flags.reduce<{ [key: string]: boolean }>((acc, { name, is_active_for_user }) => {
        acc[name] = is_active_for_user
        return acc
      }, {}),
    }

    _resetUserContext()
  }

  async function _fetchFollowingCompanies(): Promise<void> {
    const data = await $fetch<{ results: { company: Company }[] }>('v2/user/companies/following', useFetchSharedOptions())

    state.value = {
      ...state.value,
      followingCompanies: data.results.map(({ company }: { company: Company }) => company),
    }
  }

  function _setDeviceInfo(clientJS: ClientJS): void {
    state.value.clientDevice.fingerprint = clientJS.getFingerprint().toString() || Date.now()
    state.value.clientDevice.browser = clientJS.getBrowser() || null
    state.value.clientDevice.browserVersion = clientJS.getBrowserMajorVersion() || null
    state.value.clientDevice.os = _getClientOS(clientJS)
    state.value.clientDevice.osVersion = clientJS.getOSVersion() || null
    state.value.clientDevice.isMobile = clientJS.isMobile()
    state.value.clientDevice.isMobileIOS = clientJS.isMobileIOS()
    state.value.clientDevice.device = clientJS.getDevice() || null
  }

  function _getClientOS(client: ClientJS) {
    const os = client.getOS()

    if (client.isMac() || os === 'iOS') {
      return 'mac'
    }
    if (client.isWindows() || os === 'Android') {
      return 'windows'
    }
    if (client.isLinux()) {
      return 'linux'
    }

    return ''
  }

  function _destroy(): void {
    state.value = {
      ...state.value,
      user: undefined,
      token: '',
      requestSetting: undefined,
      followingCompanies: [],
      flags: {},
    }
    selectedCompany.value = undefined
    inboxCount.value = 0

    $analytics?.unidentify()

    if (process.client) {
      shutdownIntercom()
    }
  }

  function _resetUserContext() {
    if (state.value.user) {
      $analytics?.identify(`${state.value.user.id}`, {
        name: state.value.user.name ?? '',
        emailAddress: state.value.user.email_address ?? '',
        profileImage: state.value.user.profile_image ?? '',
        linkedIn: state.value.user.linkedin_id ?? '',
        isSuperAdmin: state.value.user.is_super_admin ?? '',
        userRole: userRole.value,
        companyName: selectedCompany.value?.name ?? '',
        companies: state.value.user.companies?.map(u => u?.id) ?? [],
        planType: selectedCompany.value?.payment_plan?.plan_tier ?? '',
      })
    }

    if (process.client) {
      Intercom({
        app_id: config.public.INTERCOM_APP_ID,
        user_type: userRole.value,
        user_id: String(state.value.user?.id),
        name: state.value.user?.name as string,
        email: state.value.user?.email_address as string,
        company: selectedCompany.value && {
          company_id: selectedCompany.value.id,
          name: selectedCompany.value.name,
          plan: selectedCompany.value.payment_plan?.plan_tier,
        },
        has_setup_bam: !!state.value.requestSetting,
        has_bam: state.value.flags['BOOK_A_MEETING_ENABLED'] === true,
        has_sam: state.value.flags['CONNECT_SCHEDULE_MEETING_ENABLED'] === true,
        has_aaq: selectedCompany.value?.features_enabled?.includes('ask_a_question') ?? false,
      })
    }
  }

  return {
    initialize,
    refreshToken,
    create,
    createWithAlternateService,
    logout,
    setGuestUserInfo,
    setFollowCompany,
    userRole,
    userHasCompanies,
    doesUserManageCompany,
    hasRole,
    selectedCompany: readonly(selectedCompany),
    setSelectedCompany,
    inboxCount: readonly(inboxCount),
    state: readonly(state),
  }
}
