import { tail, forEach, last } from 'ramda'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('scroll-spy', {
    mounted(el, { value: offsetTop }) {
      const links = el.querySelectorAll('li a')
      const sections = _getSections(links)

      if (!sections) return

      links[0]?.classList.add('active')
      el.boundedScroll = _onScrollHandler.bind(el, offsetTop, links, sections as Element[])
      window.addEventListener('scroll', el.boundedScroll)
    },

    unmounted(el) {
      window.removeEventListener('scroll', el.boundedScroll)
    },
  })
})

function _onScrollHandler(offsetTop: number, links: Element[], sections: Element[]) {
  const maxScroll = document.body.scrollHeight - window.innerHeight
  const delta = 5

  let highlightedSection: Element | undefined
  for (let i = sections.length - 1; i >= 0; --i) {
    const section = sections[i]

    if (window.scrollY >= _getOffsetTop(section) - (offsetTop || 0)) {
      forEach((link: Element) => link.classList.remove('active'), links)
      highlightedSection = section
      break
    }
  }

  // Special case if we're at the bottom
  if (window.scrollY >= maxScroll - delta) {
    highlightedSection = last(sections)
  }

  return (
    highlightedSection &&
    ([] as any).find
      .call(links, (el: any) => tail(el.getAttribute('href')) === (highlightedSection as any).getAttribute('id'))
      .classList.add('active')
  )
}

function _getOffsetTop(el: Element) {
  const rect = el.getBoundingClientRect()
  return Math.floor(rect.top + (window.pageYOffset || el.scrollTop) - (el.clientTop || 0))
}

function _getSections(links: NodeList): unknown {
  const query: string = ([] as any).map
    .call(links, (el: Element) => el.getAttribute('href'))
    .reduce(
      _transduce(
        (href: string) => href.slice(href.indexOf('#')),
        (href: string) => href.charAt(0) === '#'
      ),
      []
    )
    .join(',')

  if (query) {
    return document.querySelectorAll(query)
  }
}

function _transduce(xf: Function, pred: Function) {
  return (acc: unknown[], val: unknown) => {
    const transformedData = xf(val)
    return !pred(transformedData) ? acc : acc.concat(transformedData)
  }
}
