import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBannerBlock, LazyBenefitsBlock, LazyCareerPositionsBlock, LazyCompanyLogosCarouselBlock, LazyContactBlock, LazyCultureBlock, LazyDiscoveryBlock, LazyFeaturesListBlock, LazyFewerStepsBlock, LazyHeroBlock, LazyIconTextListBlock, LazyImageBlock, LazyInteractiveDeckBlock, LazyLiveTourBlock, LazyMarketingEmailBlock, LazyMultiColumnTitleSubTitleBlock, LazyNewsletterVideoBlock, LazyPlansBlock, LazyPreFooterCTABlock, LazyPreFooterImageBlock, LazyResponsiveImage, LazyReviewsBlock, LazySchoolOfMiningPromoBlock, LazySolutionsBlock, LazySteveQuoteBlock, LazyTextImageBlock, LazyTitleSubTitleBlock, LazyTitleSubtitleOverlayImageBlock, LazyUserQuotesBlock, LazyVideosBlock, LazyVrifyLogoBlock } from '#components'
const lazyGlobalComponents = [
  ["BannerBlock", LazyBannerBlock],
["BenefitsBlock", LazyBenefitsBlock],
["CareerPositionsBlock", LazyCareerPositionsBlock],
["CompanyLogosCarouselBlock", LazyCompanyLogosCarouselBlock],
["ContactBlock", LazyContactBlock],
["CultureBlock", LazyCultureBlock],
["DiscoveryBlock", LazyDiscoveryBlock],
["FeaturesListBlock", LazyFeaturesListBlock],
["FewerStepsBlock", LazyFewerStepsBlock],
["HeroBlock", LazyHeroBlock],
["IconTextListBlock", LazyIconTextListBlock],
["ImageBlock", LazyImageBlock],
["InteractiveDeckBlock", LazyInteractiveDeckBlock],
["LiveTourBlock", LazyLiveTourBlock],
["MarketingEmailBlock", LazyMarketingEmailBlock],
["MultiColumnTitleSubTitleBlock", LazyMultiColumnTitleSubTitleBlock],
["NewsletterVideoBlock", LazyNewsletterVideoBlock],
["PlansBlock", LazyPlansBlock],
["PreFooterCTABlock", LazyPreFooterCTABlock],
["PreFooterImageBlock", LazyPreFooterImageBlock],
["ResponsiveImage", LazyResponsiveImage],
["ReviewsBlock", LazyReviewsBlock],
["SchoolOfMiningPromoBlock", LazySchoolOfMiningPromoBlock],
["SolutionsBlock", LazySolutionsBlock],
["SteveQuoteBlock", LazySteveQuoteBlock],
["TextImageBlock", LazyTextImageBlock],
["TitleSubTitleBlock", LazyTitleSubTitleBlock],
["TitleSubtitleOverlayImageBlock", LazyTitleSubtitleOverlayImageBlock],
["UserQuotesBlock", LazyUserQuotesBlock],
["VideosBlock", LazyVideosBlock],
["VrifyLogoBlock", LazyVrifyLogoBlock],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
