<template>
  <component
    :is="component"
    class="border border-black outline-none cursor-pointer v-button transition-all duration-300 shadow-sm"
    :to="to"
    :href="!!to ? undefined : href"
    :disabled="disabled || isLoading"
    :inert="disabled || isLoading"
    :type="component === 'button' ? type : null"
    :class="[
      `v-btn-${variant}`,
      `font-${fontWeight}`,
      `${sizeClasses}`,
      rounded ? 'rounded-full' : 'rounded-md',
      { 'no-shadow': noShadow, loading: isLoading, disabled: disabled || isLoading },
    ]"
    @click="handleClick"
  >
    <div v-if="isLoading" class="loading-spinner animate-spin" />
    <slot v-else />
  </component>
</template>

<script lang="ts" setup>
const { $analytics } = useNuxtApp()

const props = defineProps({
  component: {
    type: [Object, String],
    default: 'button',
  },
  variant: {
    required: false,
    type: String,
    default: 'light',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'button',
  },
  rounded: {
    type: [Boolean, String],
    default: false,
  },
  noShadow: {
    type: [Boolean, String],
    default: false,
  },
  fontWeight: {
    type: String,
    default: 'normal',
  },
  to: {
    type: [String, Object],
    default: null,
  },
  href: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: 'xl',
  },
  analyticsEventName: {
    type: String,
    default: null,
  },
  location: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['click'])

onMounted(() => {
  if (props.analyticsEventName) {
    $analytics.track(`showedButton${props.analyticsEventName}`, { location: props.location })
  }
})

function handleClick() {
  if (!props.disabled && !props.isLoading) {
    emit('click')
  }
}

const sizeClasses = computed(() => {
  return props.size
    .split(' ')
    .map(cn => {
      if (cn.includes(':')) {
        cn = `${cn.split(':')[1]}-bp-${cn.split(':')[0]}`
      }

      return `size-${cn}`
    })
    .join(' ')
})
</script>

<style lang="stylus" scoped>
.loading-spinner
  display: inline-block;
  border: 3px solid rgba(255, 255, 255, .3);
  border-radius: 50%;
  border-top-color: var(--currentColor);

button:disabled, button[disabled], .disabled
  cursor: default

  &:hover
    cursor: default

  &:not(.loading)
    @apply bg-gray-100 text-gray-400 border-gray-100

    &:hover
      @apply bg-gray-100 border-gray-100 text-gray-400 opacity-100

a
  @apply text-gray-900

.v-btn-light
  @apply text-gray-900 bg-white border-gray-300

  &:hover
    @apply bg-gray-50 border-gray-900

  &:focus
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #222222

.v-btn-dark
  @apply bg-gray-900 text-white

  &:hover
    @apply bg-gray-700 border-gray-700 text-white

  &:focus
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #222222

.v-btn-danger
  @apply bg-red-800 text-white border-red-800

  &:hover
    @apply bg-red-200 text-red-800

  &:focus
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #E11D48

.v-btn-copper-outline
  @apply bg-white text-copper-800 border-copper-800

  &:hover
    @apply bg-gray-50

  &:disabled
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #74A9B3

  &:focus
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #74A9B3

.v-btn-copper
  @apply bg-copper-800 text-white border-copper-800

  &:hover
    @apply bg-copper-500 border-copper-500 opacity-70

  &:focus
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #74A9B2

.v-btn-copper-borderless
  @apply bg-white text-copper-600 border-0

  &:hover
    @apply bg-copper-100 text-copper-700

  &:disabled
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #74A9B3

  &:focus
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #74A9B3

.v-btn-bronze-borderless
  @apply bg-white text-bronze-600 border-0

  &:hover
    @apply bg-bronze-50

  &:disabled
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #74A9B3

  &:focus
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #74A9B3

.v-btn-gold
  @apply bg-gold-800 text-white border-gold-800
  &:hover
    @apply bg-gold-700 border-gold-700

.v-btn-bronze
  @apply bg-bronze-300 text-white border-bronze-300
  &:hover
    @apply bg-bronze-200 border-bronze-200

.v-btn-green
  @apply bg-green-700 text-white border-green-700
  &:hover
    @apply bg-green-600 border-green-600

.v-btn-black
  @apply bg-black text-white border-black
  &:hover
    @apply bg-gray-900 border-gray-900

.no-shadow
  @apply shadow-none
  &:focus, &:disabled
    @apply shadow-none

.v-btn-silver-blue
  @apply bg-blue-500 text-white border-blue-500
  &:hover
    @apply bg-blue-400 border-blue-400

.v-btn-gray
  @apply bg-gray-700 text-white

  &:hover
    @apply bg-gray-800 border-gray-800 text-white

  &:focus
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #222222

.v-btn-dark-gray-borderless
  @apply bg-gray-800 text-white border-transparent

  &:hover
    @apply bg-gray-900 text-white

  &:focus
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #222222

.v-btn-flat
  @apply border-0 bg-transparent text-current hover_bg-gray-700

// Sizes
xs =
  @apply text-xs px-3 py-2

  .loading-spinner
    @apply h-4 w-4
    margin-bottom: -0.1875rem;

.size-xs
  {xs}

sm =
  @apply text-sm px-3 py-2

  .loading-spinner
    @apply h-4 w-4
    margin-bottom: -0.1875rem;

.size-sm
  {sm}


md =
  @apply text-sm px-4 py-2

  .loading-spinner
    @apply h-4 w-4
    margin-bottom: -0.1875rem;

.size-md
  {md}

lg =
  @apply text-base px-4 py-2

  .loading-spinner
    @apply h-5 w-5 -mb-1

.size-lg
  {lg}

xl =
  @apply text-base px-6 py-3

  .loading-spinner
    @apply h-5 w-5 -mb-1

.size-xl
  {xl}


xl1 =
  @apply text-lg px-8 py-4

  .loading-spinner
    @apply h-5 w-5 -mb-1

.size-xl1
  {xl1}

@screen sm
  .size-xs-bp-sm
    {xs}
  .size-sm-bp-sm
    {sm}
  .size-md-bp-sm
    {md}
  .size-lg-bp-sm
    {lg}
  .size-xl-bp-sm
    {xl}
  .size-xl1-bp-sm
    {xl1}

@screen md
  .size-xs-bp-md
    {xs}
  .size-sm-bp-md
    {sm}
  .size-md-bp-md
    {md}
  .size-lg-bp-md
    {lg}
  .size-xl-bp-md
    {xl}
  .size-xl1-bp-md
    {xl1}

@screen lg
  .size-xs-bp-lg
    {xs}
  .size-sm-bp-lg
    {sm}
  .size-md-bp-lg
    {md}
  .size-lg-bp-lg
    {lg}
  .size-xl-bp-lg
    {xl}
  .size-xl1-bp-lg
    {xl1}

@screen xl
  .size-xs-bp-xl
    {xs}
  .size-sm-bp-xl
    {sm}
  .size-md-bp-xl
    {md}
  .size-lg-bp-xl
    {lg}
  .size-xl-bp-xl
    {xl}
  .size-xl1-bp-xl
    {xl1}

@screen xl1
  .size-xs-bp-xl1
    {xs}
  .size-sm-bp-xl1
    {sm}
  .size-md-bp-xl1
    {md}
  .size-lg-bp-xl1
    {lg}
  .size-xl-bp-xl1
    {xl}
  .size-xl1-bp-xl1
    {xl1}

@screen xl2
  .size-xs-bp-xl2
    {xs}
  .size-sm-bp-xl2
    {sm}
  .size-md-bp-xl2
    {md}
  .size-lg-bp-xl2
    {lg}
  .size-xl-bp-xl2
    {xl}
  .size-xl1-bp-xl2
    {xl1}
</style>
