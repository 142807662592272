import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_U4BUIcs89R from "/app/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import portal_vue_h7YCDNO0gl from "/app/plugins/portal-vue.ts";
import analytics_SVnwK7EMrr from "/app/plugins/analytics.ts";
import clientJS_client_qgRv2DNROG from "/app/plugins/clientJS.client.ts";
import _01_user_client_KDumI2sl4x from "/app/plugins/01.user.client.ts";
import _02_sentry_client_sABltX8K3S from "/app/plugins/02.sentry.client.ts";
import error_handler_kEP5FliEXj from "/app/plugins/error-handler.ts";
import fontawesome_cn2c4tOOHz from "/app/plugins/fontawesome.ts";
import highcharts_client_MYKkaxDq22 from "/app/plugins/highcharts.client.ts";
import scroll_spy_NvamBNNx1R from "/app/plugins/scroll-spy.ts";
import vue_gtm_client_stBjLl0OeM from "/app/plugins/vue-gtm.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_U4BUIcs89R,
  portal_vue_h7YCDNO0gl,
  analytics_SVnwK7EMrr,
  clientJS_client_qgRv2DNROG,
  _01_user_client_KDumI2sl4x,
  _02_sentry_client_sABltX8K3S,
  error_handler_kEP5FliEXj,
  fontawesome_cn2c4tOOHz,
  highcharts_client_MYKkaxDq22,
  scroll_spy_NvamBNNx1R,
  vue_gtm_client_stBjLl0OeM
]