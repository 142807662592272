import { library, config } from '@fortawesome/fontawesome-svg-core'
import type { IconPack } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(fas as IconPack, far as IconPack)

export default defineNuxtPlugin(nuxtApp => {
  // Register the component globally
  nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon)
})
