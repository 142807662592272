
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as _91_91token_93_93yJGDPTIQFxMeta } from "/app/pages/activate/[[token]].vue?macro=true";
import { default as careersb3LI3USwU5Meta } from "/app/pages/careers.vue?macro=true";
import { default as activitiesKcsUNQbUp0Meta } from "/app/pages/companies/[[id]]/activities.vue?macro=true";
import { default as indexwGX0szBKG2Meta } from "/app/pages/companies/[[id]]/index.vue?macro=true";
import { default as indexWEdmi1PbBFMeta } from "/app/pages/data-files/[[id]]/core-images/[id]/index.vue?macro=true";
import { default as indexSjpG2wi9mNMeta } from "/app/pages/data-files/[[id]]/index.vue?macro=true";
import { default as index2sxVRLkH9wMeta } from "/app/pages/data-files/[[id]]/multi-upload/index.vue?macro=true";
import { default as indexgL68lofw7CMeta } from "/app/pages/data-files/[[id]]/settings/[id]/index.vue?macro=true";
import { default as newt5rIW5aMAnMeta } from "/app/pages/data-files/[[id]]/settings/new.vue?macro=true";
import { default as indexUKcM2nhZvMMeta } from "/app/pages/data-files/[[id]]/upload/[id]/index.vue?macro=true";
import { default as feedback4hveqFnxa0Meta } from "/app/pages/decks/[[id]]/feedback.vue?macro=true";
import { default as indexzdEUqP4jCOMeta } from "/app/pages/decks/[[id]]/index.vue?macro=true";
import { default as _91_91endpoint_93_93f57Tgeu9JPMeta } from "/app/pages/discover/[[endpoint]].vue?macro=true";
import { default as _91_91id_93_93jL5uHxEL3BMeta } from "/app/pages/editor/decks/[[id]].vue?macro=true";
import { default as _91_91id_93_93I84YnoOm7bMeta } from "/app/pages/editor/models/[[id]].vue?macro=true";
import { default as _91_91id_93_93o6ytNJKb3dMeta } from "/app/pages/embed/decks/[[id]].vue?macro=true";
import { default as _91_91id_93_931Z68SHrpyJMeta } from "/app/pages/embed/decks/preview/[[id]].vue?macro=true";
import { default as _91_91id_93_93RZ8ZgwdPqAMeta } from "/app/pages/embed/videos/[[id]].vue?macro=true";
import { default as _91_91id_93_93CCtnxr85N8Meta } from "/app/pages/embed/videos/preview/[[id]].vue?macro=true";
import { default as forgot_45password1UbT4qDpFeMeta } from "/app/pages/forgot-password.vue?macro=true";
import { default as get_45startedJTSgLIAUXPMeta } from "/app/pages/get-started.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as investor_45solutionsBJYeFbjdkCMeta } from "/app/pages/investor-solutions.vue?macro=true";
import { default as acceptable_45use_45policyDbb7NtbLgTMeta } from "/app/pages/legal/acceptable-use-policy.vue?macro=true";
import { default as client_45terms_45of_45serviceUOyDhb4TpPMeta } from "/app/pages/legal/client-terms-of-service.vue?macro=true";
import { default as cookie_45noticea1o8aQJ4VsMeta } from "/app/pages/legal/cookie-notice.vue?macro=true";
import { default as copyright_45and_45ip_45infringement5YddIvX8t2Meta } from "/app/pages/legal/copyright-and-ip-infringement.vue?macro=true";
import { default as index8NBB3chSIOMeta } from "/app/pages/legal/index.vue?macro=true";
import { default as privacyE8rEcGUKW8Meta } from "/app/pages/legal/privacy.vue?macro=true";
import { default as user_45terms_45of_45serviceWFpdunr3nQMeta } from "/app/pages/legal/user-terms-of-service.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as _91_91id_93_93gR838tczZdMeta } from "/app/pages/meeting/[[id]].vue?macro=true";
import { default as _91_91id_93_93D9OKVrdZVEMeta } from "/app/pages/meetings/book/[[id]].vue?macro=true";
import { default as availability3CRCz2XkBxMeta } from "/app/pages/meetings/book/setup/availability.vue?macro=true";
import { default as congratulationsyj0yeg7mlzMeta } from "/app/pages/meetings/book/setup/congratulations.vue?macro=true";
import { default as intro9GanYwiLRkMeta } from "/app/pages/meetings/book/setup/intro.vue?macro=true";
import { default as meeting_45detailsnmRk9Dloa8Meta } from "/app/pages/meetings/book/setup/meeting-details.vue?macro=true";
import { default as share_45link4zOmjGBdW0Meta } from "/app/pages/meetings/book/setup/share-link.vue?macro=true";
import { default as sync_45calendarfgmMJFdj2kMeta } from "/app/pages/meetings/book/setup/sync-calendar.vue?macro=true";
import { default as indexwfBf60dsOxMeta } from "/app/pages/meetings/index.vue?macro=true";
import { default as _91_91id_93_93HYlZUpRV6nMeta } from "/app/pages/meetings/recordings/[[id]].vue?macro=true";
import { default as mining_45company_45solutionsmzUOKxBkRNMeta } from "/app/pages/mining-company-solutions.vue?macro=true";
import { default as password_45reset39IAnjWvPiMeta } from "/app/pages/password-reset.vue?macro=true";
import { default as _91id_93gdMwyJkYQ0Meta } from "/app/pages/press-releases/[id].vue?macro=true";
import { default as indextrm9IH0fHuMeta } from "/app/pages/press-releases/index.vue?macro=true";
import { default as _91_91id_93_93IFHsEefxDPMeta } from "/app/pages/recordings/[[id]].vue?macro=true";
import { default as school_45of_45miningiHUJBL5RLQMeta } from "/app/pages/school-of-mining.vue?macro=true";
import { default as signupezBbZGXKU6Meta } from "/app/pages/signup.vue?macro=true";
import { default as statusAyOFU8xai2Meta } from "/app/pages/status.vue?macro=true";
import { default as thank_45youwub3K0ZhI4Meta } from "/app/pages/thank-you.vue?macro=true";
import { default as profileWu1o0nyKd6Meta } from "/app/pages/user/profile.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "activate-token",
    path: "/activate/:token?",
    component: () => import("/app/pages/activate/[[token]].vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/app/pages/careers.vue")
  },
  {
    name: "companies-id-activities",
    path: "/companies/:id?/activities",
    component: () => import("/app/pages/companies/[[id]]/activities.vue")
  },
  {
    name: "companies-id",
    path: "/companies/:id?",
    component: () => import("/app/pages/companies/[[id]]/index.vue")
  },
  {
    name: "data-files-id-core-images-id",
    path: "/data-files/:id?/core-images/:id()",
    meta: indexWEdmi1PbBFMeta || {},
    component: () => import("/app/pages/data-files/[[id]]/core-images/[id]/index.vue")
  },
  {
    name: "data-files-id",
    path: "/data-files/:id?",
    meta: indexSjpG2wi9mNMeta || {},
    component: () => import("/app/pages/data-files/[[id]]/index.vue")
  },
  {
    name: "data-files-id-multi-upload",
    path: "/data-files/:id?/multi-upload",
    meta: index2sxVRLkH9wMeta || {},
    component: () => import("/app/pages/data-files/[[id]]/multi-upload/index.vue")
  },
  {
    name: "data-files-id-settings-id",
    path: "/data-files/:id?/settings/:id()",
    meta: indexgL68lofw7CMeta || {},
    component: () => import("/app/pages/data-files/[[id]]/settings/[id]/index.vue")
  },
  {
    name: "data-files-id-settings-new",
    path: "/data-files/:id?/settings/new",
    meta: newt5rIW5aMAnMeta || {},
    component: () => import("/app/pages/data-files/[[id]]/settings/new.vue")
  },
  {
    name: "data-files-id-upload-id",
    path: "/data-files/:id?/upload/:id()",
    meta: indexUKcM2nhZvMMeta || {},
    component: () => import("/app/pages/data-files/[[id]]/upload/[id]/index.vue")
  },
  {
    name: "decks-id-feedback",
    path: "/decks/:id?/feedback",
    component: () => import("/app/pages/decks/[[id]]/feedback.vue")
  },
  {
    name: "decks-id",
    path: "/decks/:id?",
    meta: indexzdEUqP4jCOMeta || {},
    component: () => import("/app/pages/decks/[[id]]/index.vue")
  },
  {
    name: "discover-endpoint",
    path: "/discover/:endpoint?",
    component: () => import("/app/pages/discover/[[endpoint]].vue")
  },
  {
    name: "editor-decks-id",
    path: "/editor/decks/:id?",
    meta: _91_91id_93_93jL5uHxEL3BMeta || {},
    component: () => import("/app/pages/editor/decks/[[id]].vue")
  },
  {
    name: "editor-models-id",
    path: "/editor/models/:id?",
    meta: _91_91id_93_93I84YnoOm7bMeta || {},
    component: () => import("/app/pages/editor/models/[[id]].vue")
  },
  {
    name: "embed-decks-id",
    path: "/embed/decks/:id?",
    meta: _91_91id_93_93o6ytNJKb3dMeta || {},
    component: () => import("/app/pages/embed/decks/[[id]].vue")
  },
  {
    name: "embed-decks-preview-id",
    path: "/embed/decks/preview/:id?",
    meta: _91_91id_93_931Z68SHrpyJMeta || {},
    component: () => import("/app/pages/embed/decks/preview/[[id]].vue")
  },
  {
    name: "embed-videos-id",
    path: "/embed/videos/:id?",
    meta: _91_91id_93_93RZ8ZgwdPqAMeta || {},
    component: () => import("/app/pages/embed/videos/[[id]].vue")
  },
  {
    name: "embed-videos-preview-id",
    path: "/embed/videos/preview/:id?",
    meta: _91_91id_93_93CCtnxr85N8Meta || {},
    component: () => import("/app/pages/embed/videos/preview/[[id]].vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/app/pages/forgot-password.vue")
  },
  {
    name: "get-started",
    path: "/get-started",
    component: () => import("/app/pages/get-started.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "investor-solutions",
    path: "/investor-solutions",
    component: () => import("/app/pages/investor-solutions.vue")
  },
  {
    name: "legal-acceptable-use-policy",
    path: "/legal/acceptable-use-policy",
    component: () => import("/app/pages/legal/acceptable-use-policy.vue")
  },
  {
    name: "legal-client-terms-of-service",
    path: "/legal/client-terms-of-service",
    component: () => import("/app/pages/legal/client-terms-of-service.vue")
  },
  {
    name: "legal-cookie-notice",
    path: "/legal/cookie-notice",
    component: () => import("/app/pages/legal/cookie-notice.vue")
  },
  {
    name: "legal-copyright-and-ip-infringement",
    path: "/legal/copyright-and-ip-infringement",
    component: () => import("/app/pages/legal/copyright-and-ip-infringement.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/app/pages/legal/index.vue")
  },
  {
    name: "legal-privacy",
    path: "/legal/privacy",
    component: () => import("/app/pages/legal/privacy.vue")
  },
  {
    name: "legal-user-terms-of-service",
    path: "/legal/user-terms-of-service",
    component: () => import("/app/pages/legal/user-terms-of-service.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    component: () => import("/app/pages/maintenance.vue")
  },
  {
    name: "meeting-id",
    path: "/meeting/:id?",
    meta: _91_91id_93_93gR838tczZdMeta || {},
    component: () => import("/app/pages/meeting/[[id]].vue")
  },
  {
    name: "meetings-book-id",
    path: "/meetings/book/:id?",
    meta: _91_91id_93_93D9OKVrdZVEMeta || {},
    component: () => import("/app/pages/meetings/book/[[id]].vue")
  },
  {
    name: "meetings-book-setup-availability",
    path: "/meetings/book/setup/availability",
    meta: availability3CRCz2XkBxMeta || {},
    component: () => import("/app/pages/meetings/book/setup/availability.vue")
  },
  {
    name: "meetings-book-setup-congratulations",
    path: "/meetings/book/setup/congratulations",
    meta: congratulationsyj0yeg7mlzMeta || {},
    component: () => import("/app/pages/meetings/book/setup/congratulations.vue")
  },
  {
    name: "meetings-book-setup-intro",
    path: "/meetings/book/setup/intro",
    meta: intro9GanYwiLRkMeta || {},
    component: () => import("/app/pages/meetings/book/setup/intro.vue")
  },
  {
    name: "meetings-book-setup-meeting-details",
    path: "/meetings/book/setup/meeting-details",
    meta: meeting_45detailsnmRk9Dloa8Meta || {},
    component: () => import("/app/pages/meetings/book/setup/meeting-details.vue")
  },
  {
    name: "meetings-book-setup-share-link",
    path: "/meetings/book/setup/share-link",
    meta: share_45link4zOmjGBdW0Meta || {},
    component: () => import("/app/pages/meetings/book/setup/share-link.vue")
  },
  {
    name: "meetings-book-setup-sync-calendar",
    path: "/meetings/book/setup/sync-calendar",
    meta: sync_45calendarfgmMJFdj2kMeta || {},
    component: () => import("/app/pages/meetings/book/setup/sync-calendar.vue")
  },
  {
    name: "meetings",
    path: "/meetings",
    meta: indexwfBf60dsOxMeta || {},
    component: () => import("/app/pages/meetings/index.vue")
  },
  {
    name: "meetings-recordings-id",
    path: "/meetings/recordings/:id?",
    meta: _91_91id_93_93HYlZUpRV6nMeta || {},
    component: () => import("/app/pages/meetings/recordings/[[id]].vue")
  },
  {
    name: "mining-company-solutions",
    path: "/mining-company-solutions",
    component: () => import("/app/pages/mining-company-solutions.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    component: () => import("/app/pages/password-reset.vue")
  },
  {
    name: "press-releases-id",
    path: "/press-releases/:id()",
    component: () => import("/app/pages/press-releases/[id].vue")
  },
  {
    name: "press-releases",
    path: "/press-releases",
    component: () => import("/app/pages/press-releases/index.vue")
  },
  {
    name: "recordings-id",
    path: "/recordings/:id?",
    meta: _91_91id_93_93IFHsEefxDPMeta || {},
    component: () => import("/app/pages/recordings/[[id]].vue")
  },
  {
    name: "school-of-mining",
    path: "/school-of-mining",
    component: () => import("/app/pages/school-of-mining.vue")
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/app/pages/signup.vue")
  },
  {
    name: "status",
    path: "/status",
    component: () => import("/app/pages/status.vue")
  },
  {
    name: "thank-you",
    path: "/thank-you",
    component: () => import("/app/pages/thank-you.vue")
  },
  {
    name: "user-profile",
    path: "/user/profile",
    component: () => import("/app/pages/user/profile.vue")
  }
]