import amplitude from 'amplitude-js'
import isChromiumEdge from '@/lib/is-chromium-edge'

/**
 * All analytics related code should be within this class which allows the
 * global usage of directives created (see OnClickAnalytics for an example) and methods
 * (used via this.$analytics.[method_name]) in other files.
 */

/**
 * This class contains the methods necessary for analytics tracking
 */
export class Analytics {
  /**
   * Sends a tracking events with some properties to the tracking services and returns the boolean association
   * of the response based on whether or not the calls were successful or not
   */
  static async track(eventName: string, analyticsMetaData: any = {}) {
    const isChromiumEdgeBrowser = isChromiumEdge()

    if (isChromiumEdgeBrowser) {
      analyticsMetaData.isChromiumBrowser = isChromiumEdgeBrowser
    }

    const amplitudeResponse = amplitude.getInstance().logEvent(eventName, analyticsMetaData)

    return !!amplitudeResponse
  }

  // changes the users tracking preferences to be opted out so that their actions are not tracked
  static async optOut() {
    amplitude.getInstance().setOptOut(true)
  }

  // identifies a user under a unique user ID
  static identify(userId: string | null, userProperties: any) {
    amplitude.getInstance().setUserId(userId)
    amplitude.getInstance().setUserProperties(userProperties)
  }

  // unidentifies the user
  static unidentify() {
    amplitude.getInstance().setUserId(null)
    amplitude.getInstance().regenerateDeviceId()
  }
}

const handleAnalytics = (event: any) => {
  const eventName = event.currentTarget.event_name
  Analytics.track(eventName)
}

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()

  const token = config.public.AMPLITUDE_API_KEY
  if (!token) throw new Error('AMPLITUDE_API_KEY missing')
  amplitude
    .getInstance()
    .init(token, undefined, { includeReferrer: true, unsetParamsReferrerOnNewSession: true, saveParamsReferrerOncePerSession: false })

  nuxtApp.vueApp.directive('analytics', {
    mounted(el, binding) {
      el.event_name = binding.value
      el.addEventListener('click', handleAnalytics)
    },
    unmounted(el) {
      el.removeEventListener('click', handleAnalytics)
    },
  })

  return {
    provide: {
      analytics: Analytics,
    },
  }
})
