<template lang="pug">
component.appearance-none.bg-transparent.border-b.p-0.transition.hover_opacity-50.outline-none.focus_ring.focus_ring-gray-900(
  :is="component === 'NuxtLink' ? NuxtLink : component"
  @click="$emit('click')"
  :class="`font-${fontWeight}`"
  :to="to"
)
  slot
</template>

<script lang="ts" setup>
const NuxtLink = resolveComponent('NuxtLink')

defineProps({
  component: {
    type: [Object, String],
    default: 'NuxtLink',
  },
  fontWeight: {
    type: String,
    default: 'semibold',
  },
  to: {
    type: [Object, String],
    default: null,
  },
})

defineEmits(['click'])
</script>
