import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter()
  const {
    public: { RELEASE, SENTRY_ENDPOINT, SENTRY_ENV },
  } = useRuntimeConfig()

  if (!SENTRY_ENDPOINT) {
    console.warn('Sentry DSN not found, skipping Sentry initialization')
    return
  }

  const { state: userState } = useUser()

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: SENTRY_ENDPOINT as string,
    environment: SENTRY_ENV,
    integrations: [
      Sentry.vueIntegration({
        tracingOptions: {
          trackComponents: true,
          timeout: 2000,
          hooks: ['mount', 'update', 'unmount'],
        },
      }),
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    initialScope: {
      user: {
        email: userState.value?.user?.email_address || undefined,
      },
    },
    release: RELEASE as string,
    replaysSessionSampleRate: 0.15,
    replaysOnErrorSampleRate: 0.15,
    tracePropagationTargets: ['localhost', /^https:\/\/(.*\.)?vrify\.com/],
    tracesSampleRate: 0.15,
  })

  watch(
    () => userState.value?.user?.email_address,
    email => {
      Sentry.withScope(scope => {
        scope.setUser({ email: email || undefined })
      })
    },
    { immediate: true },
  )

  return {
    provide: {
      sentry: Sentry,
    },
  }
})
