export default function (func: Function, wait: number, immediate = false) {
  let timeout: NodeJS.Timeout | undefined

  return function executedFunction(...args: any) {
    // @ts-ignore
    let context: any = this

    let later = function () {
      timeout = undefined
      if (!immediate) func.apply(context, args)
    }

    let callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}
