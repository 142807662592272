import { API_LOGOUT_ENDPOINT, API_REFRESH_ENDPOINT } from '@/lib/fetch-constants'
import { jwtDecode } from 'jwt-decode'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'
import type { FetchContext } from 'ofetch'

export function useFetchSharedOptions(): NitroFetchOptions<NitroFetchRequest> {
  const globalFetchHeaders = useState<{ [key: string]: string }>('global-fetch-headers', () => ({ 'X-Application': 'web' }))
  const { state: userState, refreshToken } = useUser()
  const config = useRuntimeConfig()

  return {
    baseURL: config.public.API_ENDPOINT,
    headers: globalFetchHeaders.value,
    async onRequest({ request, options }: FetchContext) {
      if (!userState.value.token) return

      options.headers = new Headers(options.headers)

      if (!request.toString().startsWith('http')) {
        options.headers.set('Authorization', `Bearer ${userState.value.token}`)
      }

      if (request.toString() === API_REFRESH_ENDPOINT || request.toString() === API_LOGOUT_ENDPOINT) return

      const { exp } = jwtDecode<{ exp: number }>(userState.value.token)
      try {
        if (exp * 1000 - 30000 <= Date.now()) {
          await refreshToken()
          if (!request.toString().startsWith('http')) {
            options.headers.set('Authorization', `Bearer ${userState.value.token}`)
          }
        }
      } finally {
        return
      }
    },
  }
}
