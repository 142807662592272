export default defineNuxtRouteMiddleware(async to => {
  if (process.server) return

  const switches = useState<Record<string, boolean>>('globalSwitches')
  const config = useRuntimeConfig()

  await callOnce(async () => {
    const result = await $fetch<{ name: string; active: boolean }[]>('v2/switches', { baseURL: config.public.API_ENDPOINT })
    switches.value = {}

    for (const item of result) {
      switches.value[item.name] = item.active
    }
  })

  // HTTP 303 Other Redirect for system maintenance purposes
  if (switches.value && switches.value['MAINTENANCE'] && to.path !== '/maintenance') {
    return navigateTo({ path: '/maintenance' }, { redirectCode: 303 })
  } else if (switches.value && !switches.value['MAINTENANCE'] && to.path === '/maintenance') {
    return navigateTo({ path: '/' }, { redirectCode: 303 })
  }
})
