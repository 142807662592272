import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()

  const id = config.public.GTM_ID

  if (id) {
    nuxtApp.vueApp.use(
      createGtm({
        id: id,
        enabled: true,
        debug: false,
        loadScript: true,
        vueRouter: useRouter(),
      })
    )
  }
})
