export default defineNuxtRouteMiddleware(async to => {
  // redirect the deprecated explore projects/companies/decks pages to the new discover page
  const exploreCompanies = to.path.match(/\/explore\/companies\/(.+)$/)
  if (to.path.match(/\/companies\/?$/) && !to.path.includes('/discover')) {
    return navigateTo({ path: '/discover', query: to.query }, { redirectCode: 301 })
  } else if (exploreCompanies) {
    return navigateTo({ path: `/companies/${exploreCompanies[1]}`, query: to.query }, { redirectCode: 301 })
  }

  const exploreDecks = to.path.match(/\/explore\/decks\/(.+)$/)
  if (to.path.match(/\/decks\/?$/) && !to.path.includes('/discover')) {
    return navigateTo({ path: '/discover/decks', query: to.query }, { redirectCode: 301 })
  } else if (exploreDecks) {
    return navigateTo({ path: `/decks/${exploreDecks[1]}`, query: to.query }, { redirectCode: 301 })
  }

  const exploreProjects = to.path.match(/\/explore\/projects\/(.+)$/)
  if (to.path.match(/\/projects\/?$/) && !to.path.includes('/discover')) {
    return navigateTo({ path: '/discover/projects', query: to.query }, { redirectCode: 301 })
  } else if (exploreProjects) {
    return navigateTo({ path: `/projects/${exploreProjects[1]}`, query: to.query }, { redirectCode: 301 })
  }

  const newsfeed = to.path.match(/(\/explore)?\/newsfeed/)
  if (newsfeed) {
    return navigateTo({ path: to.path.replace(newsfeed[0], '/press-releases'), query: to.query }, { redirectCode: 301 })
  }

  const betaEditor = to.path.match(/\/beta\/editor\/(.+)$/)
  if (betaEditor) {
    return navigateTo({ path: `/editor/${betaEditor[1]}`, query: to.query }, { redirectCode: 301 })
  }
})
