/**
 * Determines if the browser is Chromium Edge
 */
export default () => {
  if (!process.client) return false

  const userAgent = navigator.userAgent
  const chromiumUserAgent = 'Edg/'

  return userAgent.indexOf(chromiumUserAgent) > -1
}
